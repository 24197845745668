import { useEffect, useRef, useState } from "react";
import { projectFirestore } from "../firebase/config";

export const useCollection = (collection, _query, _orderby) => {
  const [documents, setDocuments] = useState(null);
  const [errors, setErrors] = useState(null);

  //   if we dont use a ref --> infinite loop in useeffect
  // _query is an array and is "different on every function call"
  const query = useRef(_query).current;
  const orderBy = useRef(_orderby).current;

  useEffect(() => {
    let ref = projectFirestore.collection(collection);

    if (query) {
      ref = ref.where(...query);
    }

    if (orderBy) {
      ref = ref.orderBy(...orderBy);
    }

    const unsubscribe = ref.onSnapshot(
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        //   update state
        setDocuments(results);
        setErrors(null);
      },
      (errors) => {
        console.log(errors);
        setErrors("Could not fetch the data", errors.message);
      }
    );

    //  unsubscribe on unmount
    return () => unsubscribe();
  }, [collection, query, orderBy]);

  return { documents, errors };
};
