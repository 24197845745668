import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyApRadJY2fcn_IMhCNNDPcjlpTuhXUvwGQ",
  authDomain: "mymoney-efa1d.firebaseapp.com",
  projectId: "mymoney-efa1d",
  storageBucket: "mymoney-efa1d.appspot.com",
  messagingSenderId: "691387577530",
  appId: "1:691387577530:web:d47fb0317192ec90f93e83",
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init service
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

// timestamp
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore, projectAuth, timestamp };
